import uniqueIdGenerator from '@/utils/generateUniqueId'

export const getAppShare = async ( lang ) => {
    const res = await fetch(
      `/api/generic?apiUrl=${process.env.NEXT_PUBLIC_API_URL}/app/app-share`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          locale: lang,
          Cookie: `i18next=${lang}`,
          authorization: window.localStorage.getItem('authToken') ?? '',
        },
      },
    )
    const response = await res.json()
   return response?.data?.data
}

export const getUserListingDetails = async (lang) => {
  try {
    const res = await fetch(
      `/api/generic?apiUrl=${process.env.NEXT_PUBLIC_GATEWAY_URL_V2}listing-user-details`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          locale: lang,
          Cookie: `i18next=${lang}`,
          authorization: window.localStorage.getItem('authToken') ?? '',
          'x-request-id': uniqueIdGenerator.getId()
        },
      },
    )
    if(!res.ok){
      throw new Error(`Request failed with status: ${res.status}`)
    }
    const response = await res.json()
    return response?.data?.data
  } catch (error) {
    console.error("Error occurred:", error)
    return null
  }
}
