import React, { useEffect, useState } from 'react'
import { businessAppHandler, isUserLoggedIn, pushDataLayerEventHandler } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { isDesktop } from 'react-device-detect'
import styles from './style.module.scss'
import { getUserListingDetails } from 'services/dashboardService'

let clientMethods = null

const OpenAppHeader = (props) => {
  const { pageType } = props
  const containerClasses = styles.container
  const wasaltBusiness = false // Change to useState when needed to set wasaltBusiness
  const [listingDetails, setListingDetails] = useState(null)
  const { t, lang } = useTranslation('translations')
  const [onLoadItems, setOnLoadItems] = useState({
    openAppLink: `intent://scan/#Intent;scheme=wasalt;package=com.wasalt.app;end?utm_source='m-site'&utm_medium=${pageType}&utm_campaign=web_smart_banner_installs_android`,
  })

  const importClientMethods = async () => {
    if (!clientMethods) {
      clientMethods = await import(/*webpackChunkName : "clientMethods"*/'../SEOHeader/Methods/onClickMethods')
    }
  }
  useEffect(() => {
    setOnLoadItems({
      ...onLoadItems,
      openAppLink: getAppLink(),
    })
    if (isUserLoggedIn()) {
      getListingDetails()
    }
  }, [])

  const getListingDetails = async () => {
    setListingDetails(await getUserListingDetails(lang))
  }

  const getAppLink = () => {
    return `https://webtoappadhfjk.onelink.me/K99z/kug3h5yq` 
  }
  const appOpenHandler = () => {
    pushDataLayerEventHandler(window, lang, 'smart_banner_clicked', pageType, { banner_action: 'Open App' })
  }

  const redirectToApp = async () => {
    await importClientMethods()
    clientMethods.redirectToApp({ listingDetails, lang, pageType, isDesktop, businessAppHandler })
  }

  return (
    <div className={`${containerClasses} ${wasaltBusiness && styles.businessContainer}`}>
      <div className={styles.appIcon}>
        {wasaltBusiness ? (
          <Image width={36} height={36} src='/images/small_logo_busigness.svg' alt={t('common.Wasalt_Business')} />
        ) : (
          <Image
            width={36}
            height={36}
            src='/images/small_logo.svg'
            alt='Small Logo'
          />
        )}
      </div>
      <div>
        <p className={styles.app_heading}>{wasaltBusiness ? t('common.Wasalt_Business') : t('common.APP_NAME')}</p>
      </div>
      {wasaltBusiness ? (
        <div className={styles.AppOpener} onClick={redirectToApp}>
          {t('common.Get_App')}
        </div>
      ) : (
        <a href={onLoadItems.openAppLink} className={styles.AppOpener} onClick={appOpenHandler}>
          {t('common.OPENAPP')}
        </a>
      )}
    </div>
  )
}

export default OpenAppHeader
